<template>
  <div v-if="isMobile">
    <div class="my-6">
      <h3 class="font-bold">Editar informações do SubProduto</h3>
      <span class="text-sm text-gray-500"
        >Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
      </span>
    </div>

    <div class="card shadow-sm border p-8 bg-white">
      <div class="block">
        <div class="flex-1 my-3">
          <Input
            type="datalist"
            id="tipo"
            v-model="data.id_tipo_produto"
            label="Tipos"
            :data="tipos"
            placeholder="Selecione um Tipo de Produto"
          />
        </div>
        <div class="flex-1 my-3">
          <Input
            type="default"
            v-model="data.nome"
            label="Nome"
            placeholder="Nome"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-5">
      <Button @click="edit" color="primary" text="Salvar" class="btn" />
    </div>
  </div>
  <div v-else>
    <div class="my-6">
      <h3 class="font-bold">Editar SubTipo de Produto</h3>
      <span class="text-sm text-gray-500"
        >Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
      </span>
    </div>

    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex">
        <div class="flex-1 mx-3">
          <Input
            type="datalist"
            id="tipo"
            v-model="data.id_tipo_produto"
            label="Tipos"
            :data="tipos"
            placeholder="Selecione um Tipo de Produto"
          />
        </div>
        <div class="flex-1 mx-3">
          <Input
            type="default"
            v-model="data.nome"
            label="Nome"
            placeholder="Nome"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-5">
      <Button @click="edit" color="primary" text="Salvar" />
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PUT } from "../../services/api";
import { toDate, toDateUS } from "../../services/formater";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";

export default {
  name: "EditarSubTipos",
  components: {
    Input,
    Button,
  },
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const data = ref({});
    const tipos = ref([]);

    onMounted(async () => {
      loader.open();

      try {
        const tipoRes = await GET("tipo_produto");

        tipoRes.forEach((tipo) =>
          tipos.value.push({ label: tipo.nome, value: tipo.id })
        );

        data.value = await GET(`subtipo_produto/${route.params.id}`);

        const [tipoSelected] = tipos.value.filter(
          (tipo) => tipo.value == data.value.id_tipo_produto
        );

        data.value["id_tipo_produto"] = tipoSelected.label;
      } catch (e) {
        alert.open("Erro", e, "danger");
      }

      loader.close();
    });

    const edit = async () => {
      const obj = ref({});

      obj.value = { ...data.value };

      const [tipoSelected] = tipos.value.filter(
        (tipo) => tipo.label == data.value.id_tipo_produto
      );

      obj.value["id_tipo_produto"] = tipoSelected.value;

      const validate = isEmpty(obj.value);

      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open(
          "Atenção",
          "Deseja realmente editar esse item?",
          "warning",
          "Sim",
          async () => {
            try {
              loader.open();

              delete obj.value.id;

              const response = await PUT(
                `subtipo_produto/${route.params.id}`,
                obj.value
              );

              alert.open(
                "Sucesso!",
                `O item foi editado com sucesso!`,
                "success"
              );
              loader.close();
              router.push("/subtipoproduto");
            } catch (e) {
              loader.close();
              alert.open("Atenção!", e.message, "warning");
            }
          },
          modal.close()
        );
      }
    };
    return { edit, data, tipos };
  },
};
</script>

<style>
</style>